import React from 'react'
import ExperienceCard from './ExperienceCard'
import OST from '../Images/OST.png'
import freelancerLogo from '../Images/freelancer-logo.svg'
import SolidCirlce from '../Images/SolidCircle.png'
import GEDC from '../Images/GEDC.png'

const Experience = () => {

    const experiences = [
        {
            image: OST,
            company: "Open Systems Technology",
            title: "Application Development Intern",
            body: "OST is a large consultancy in which I was place on a verity of teams to develop reliable and scalable software for clients.",
            details: [
                "Full stack web development using Angular, C#, and Postgres",
                "Worked on an Agile development team",
                "Staff augmentation for the UPS team working on an application to manage global trade"
            ]
        },
        {
            image: freelancerLogo,
            company: "Freelancer.com",
            title: "Freelance Software Developer",
            body: "Freelancer.com is where I got my first taste of real software development where I was turing peoples ideas into reality.",
            details: [
                "5-star developer with 100% on-time completion rate",
                "I belong to the preferred freelancer group which contains the top 1% of freelancers on the website",
                "Web development and Desktop development",
                "https://www.freelancer.com/u/dyl10s"
            ]
        },
        {
            image: SolidCirlce,
            company: "Solid Circle",
            title: "Full Stack Development Intern",
            body: "SolidCircle is a small passionate company that created full stack web applications for the justice system.",
            details: [
                "Full stack web development using ASP.NET, and MSSQL",
                "Agile based development",
                "Constant customer engagement for requirements gathering and enhancement requests",
            ]
        },
        {
            image: GEDC,
            company: "Great Expressions Dental Centers",
            title: "Programming Intern",
            body: "Although the development team was small, the company was very large. Great Expressions has over 300 offices around the United States.",
            details: [
                "Created orthodontic imaging software used in over 100 orthopedic offices nationwide",
                "Tasked with internal software development for managing purchase orders",
                "Used VB.NET to create desktop applications",
            ]
        }
    ]


    return (
        <div id="experience" className="experience">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <h1>Work Experience</h1>
                        <p>
                            Throughout high school and college I have gotten the opportunity
                            to intern at companies of many different shapes and sizes from
                            a 10 man development team working in a small room to working at the
                            corporate offices of a dental company with over 300 offices around America.
                        </p>
                    </div>
                </div>
                <div className="row justify-content-center">
                    {experiences.map(x => {
                        return (
                            <div className="col-lg-6 col-md-12 pb-4" key={x.title}>
                                <ExperienceCard experience={x}></ExperienceCard>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default Experience;