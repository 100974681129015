import React from 'react'
import ExperienceCard from './ExperienceCard'
import LO from '../Images/LO_logo.jpg'
import GV from '../Images/gvsu.png'

const Education = () => {


    const experiences = [
        {
            image: GV,
            company: "Computer Science Major",
            title: "Grand Valley State University",
            details: [
                "GPA: 3.6",
                "Expected Graduation: May 2021",
                "Start Date: August 2017"
            ]
        },
        {
            image: LO,
            company: "High School Diploma",
            title: "Lake Orion High School",
            details: [
                "GPA: 3.75",
                "Lake Orion Computer Science Award",
                "National Honors Society"
            ]
        }
    ]

    return (
        <div id="education" className="education">
            <div className="container">
                <div className="row">
                    <div className="col-6">
                        <h1>Education</h1>
                    </div>
                </div>
                <div className="row justify-content-center">
                    {experiences.map(x => {
                        return (
                            <div className="col-lg-6 col-md-12 pb-4" key={x.title}>
                                <ExperienceCard experience={x}></ExperienceCard>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );

}

export default Education;