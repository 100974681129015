import React, { useEffect } from "react";
import me from "../Images/Me.png";

const NavBar = () => {

    useEffect(() => {
        let navbar = document.getElementById("navbar");

        window.onscroll = () => {
            if(document.body.scrollTop > 10 || document.documentElement.scrollTop > 10){
                navbar.classList.add("scrolled");
            }else{
                navbar.classList.remove("scrolled");
            }
        }
    }, [])

    return (
        <nav id="navbar" className="navbar navbar-expand-lg navbar-dark sticky-top">
        <div className="container h-100">
            <a className="navbar-brand" href="#">
            DS
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav">
                <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href="#Me">
                        Me
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#experience">
                        Experience
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#education">
                        Education
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#portfolio">
                        Portfolio
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#contact">
                        Contact Me
                    </a>
                </li>
            </ul>
            </div>
        </div>
        </nav>
    );
};

export default NavBar;
