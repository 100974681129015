import React from 'react'

const Footer = () => {
    return (
        <div id="contact" className="footer">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-6 pb-5">
                        <h1>Let's Get In Touch!</h1>
                        <p>
                            I am always looking to make connections with people from all
                            walks of life. I would love to hear from you!
                        </p>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-6 col-12 pb-5">
                        <i className="fa fa-envelope large-icon pb-3"></i>
                        <h3>Email Address</h3>
                        <h5>dylanstrohschein@gmail.com</h5>
                    </div>
                    <div className="col-md-6 col-12 pb-5">
                        <i className="fa fa-phone large-icon pb-3"></i>
                        <h3>Phone Number</h3>
                        <h5>(248) 978-7318</h5>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;