import React, { useState } from "react";
import ProjectCard from "./ProjectCard";
import vendingMachine from "../Images/vendingmachine.jpg";
import medicalDrive from "../Images/medicalalertdrive.jpg";
import clanTracker from "../Images/ClanTracker.PNG";
import splashScreen from "../Images/Splash Screen.png"
import alexaConnect from "../Images/ConnectSkill.png"
import rlSkill from "../Images/RlSkill.PNG"
import garageSkill from "../Images/GarageSkill.PNG"
import siteChange from "../Images/SiteChange.png"
import dqm from "../Images/DQM.png"

const Portfolio = () => {
  const Projects = [
    {
      title: "Grasshopper Kiosks",
      body: "Over the span of 6 months I developed a windows touch screen based solution for a cannabis vending machine. The system is fully customizable and ADA compliant. It integrates directly with the vending machines hardware over serial port connection meaning it takes care of everything from the cash acceptance to the spinning of the coils.",
      code: null,
      view: "https://grasshopperkiosks.com/",
      image: vendingMachine,
      category: ["Desktop", "Freelance"]
    },
    {
      title: "Medical Alert Drive",
      body: "These flash drives come equip with my custom software made for keeping track of a users medical information. The application was designed for easy use for anyone. After you have entered the information the application then can create generate a medical alert card with the most important details, as well as a full pdf containing all your information. This makes it easy for medical professionals to read and interpret.",
      code: null,
      view: "http://www.medicalalertbands.com/software.html",
      image: medicalDrive,
      category: ["Desktop", "Freelance"]
    },
    {
      title: "Destiny Clan Tracker",
      body: "The game, Destiny 2, has large groups of players who complete activities and events together known as clans. I was hired to create an application that would integrate with the games API to keep track of when the players would play together and award points/tags based off of configurable criteria. This application demonstrates my ability to deal with large amounts of data as it compares around half a million data points every hour.",
      code: null,
      view: null,
      image: clanTracker,
      category: ["Web", "Freelance"]
		},
		{
      title: "Rocket League Loader",
      body: "This is a current app that I develop and support and is made for modifying the memory of the game \"Rocket League\" allowing users to play on custom maps with just the click of a button.",
      code: null,
      view: "https://rocketleaguemods.com/mods/angrycarrots-rocket-league-map-loader/",
      image: splashScreen,
      category: ["Desktop", "Personal"]
    },
    {
      title: "Connect Alexa Skill",
      body: "The Connect Skill allows you to easily program your PC do run actions based on things you say to your alexa device. You can turn on and off your screens, run programs, control your PC's volume and even record/playback keyboard macros.",
      code: null,
      view: null,
      image: alexaConnect,
      category: ["Desktop", "Personal", "AmazonAlexa"]
    },
    {
      title: "Rocket League Price Checker Alexa Skill",
      body: "This alexa skill is one of my most popular skills that is currently on the store. It allows users to easily track in game item prices. The skill is constantly scrapping and comparing multiple websites to allow to best and most accurate pricing.",
      code: null,
      view: null,
      image: rlSkill,
      category: ["Personal", "AmazonAlexa"]
    },
    {
      title: "MyQ Garage Alexa Skill",
      body: "The MyQ Garage alexa skill filled a gap in Chaimberlain and Liftmaster's technology suite. Their software allows users to open and close their garage doors directly from their phones or internet. By reverse engineering their hidden API, I was able to allow 25,000 users to open and close their garages with their voice. Sadly after being threatened by the massive company I was forced to remove this skill.",
      code: null,
      view: null,
      image: garageSkill,
      category: ["Personal", "AmazonAlexa"]
    },
    {
      title: "SiteChange",
      body: "SiteChange is a modern, simple solution for all your website change detection needs. Built from the ground up for reliability and usability you not going to find an easier to use tool than SiteChange. SiteChange is free to use forever and allowing you to track 2 pages completely free of charge.",
      code: null,
      view: null,
      image: siteChange,
      category: ["Personal", "Web"]
    },
    {
      title: "Data Quality Manager",
      body: "The Data Quality Manager is software created for fast data entry and processing. This was built custom from the ground up with a custom reporting engine and user permissions. One of the main features was importing an excel based database to be used as selectable options in the system. This import process needed to be quick and not break the system when items were added and removed.",
      code: null,
      view: null,
      image: dqm,
      category: ["Freelancer", "Web"]
    },
  ];

  const [selectedCategory, setSelectedCategory] = useState("Web");

  const selectCategory = (e) => {
    setSelectedCategory(e.target.id);
  }

  return (	
  	<div id="portfolio" className="Portfolio">
      <div className="container">
        <div className="row">
          <div className="col-6">
            <h1>Making Ideas Become A Reality</h1>
            <p>For over 5 years I have been creating projects for clients as well as personal projects. The experience this has given me has shaped me into the developer that I am today.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="btn-group" role="group">
              <input onChange={(e) => selectCategory(e)} type="radio" className="btn-check" id="Web" name="projectTypes" autoComplete="off" checked={selectedCategory === "Web"} />
              <label className="btn btn-outline-primary" htmlFor="Web">
                Web
              </label>

              <input onChange={(e) => selectCategory(e)} type="radio" className="btn-check" id="Desktop" name="projectTypes" autoComplete="off" />
              <label className="btn btn-outline-primary" htmlFor="Desktop">
                Desktop
              </label>

							<input onChange={(e) => selectCategory(e)} type="radio" className="btn-check" id="AmazonAlexa" name="projectTypes" autoComplete="off" />
              <label className="btn btn-outline-primary" htmlFor="AmazonAlexa">
                Amazon Alexa
              </label>

              <input onChange={(e) => selectCategory(e)} type="radio" className="btn-check" id="Personal" name="projectTypes" autoComplete="off" />
              <label className="btn btn-outline-primary" htmlFor="Personal">
                Personal
              </label>

              <input onChange={(e) => selectCategory(e)} type="radio" className="btn-check" id="Freelance" name="projectTypes" autoComplete="off" />
              <label className="btn btn-outline-primary" htmlFor="Freelance">
                Freelance
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          {Projects.map((x) => {
            if(x.category.includes(selectedCategory)){
              return (
                <div key={x.title} className="col-xl-6 col-lg-12 project-item">
                  <ProjectCard project={x}></ProjectCard>
                </div>
              );
            }else{
              return (
                <div key={x.title} className="col-xl-6 col-lg-12 project-item hidden">
                    <ProjectCard project={x}></ProjectCard>
                </div>
              )
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
