import React from 'react'

const ProjectCard = ({project}) => {

    return (
        <div className="card project-card">
            <div className="card-body">
                <div className="row">
                    <div className="col-7">
                        <h4>{project.title}</h4>
                        <p>
                         {project.body}
                        </p>
                    </div>
                    <div className="col-5">
                        <img className="project-image" src={project.image} alt={project.title}></img>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProjectCard;