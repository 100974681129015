import React from 'react'

const ExperienceCard = ({experience}) => {
    
    return (
        <div className="card experience-card">
            <div className="card-body">
                <div className="row">
                    <div className="col-12">
                        <img className="experience-image" src={experience.image} alt={experience.title}></img>
                    </div>
                    <div className="col-12 pt-4">
                        <h4>{experience.title}</h4>
                        <h6>{experience.company}</h6>
                        <p>
                            {experience.body}
                        </p>
                        <ul>
                            {experience.details.map(x => {
                                return (<li key={x}>{x}</li>);
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default ExperienceCard;