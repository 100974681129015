import logo from './logo.svg';
import './App.css';
import Intro from './Components/Intro';
import NavBar from './Components/NavBar';
import Portfolio from './Components/Portfolio';
import Experience from './Components/Experience';
import Education from './Components/Education';
import Footer from './Components/Footer';

function App() {
  return (
    <div id="Me" className="App">
      <NavBar></NavBar>
      <Intro></Intro>
      <Experience></Experience>
      <Education></Education>
      <Portfolio></Portfolio>
      <Footer></Footer>
    </div>
  );
}

export default App;
