import React from 'react'
import me from '../Images/Me.png'

const Intro = () => {
    return (
        <div className="Intro">
            <div className="container h-100">
            <div className="row h-100">
                <div className="col-xxl-7 col-xl-6 col-lg-5 col-sm-12 Intro-Text">
                <h3>Hello, I'm</h3>
                <h1>Dylan Strohschein</h1>
                <h3 className="position">Software Developer</h3>
                <p>
                    Software development has been a large part of
                    myself after winning the science fair in 6th grade where
                    I wrote a desktop application to limit kids time on the internet.
                    Since then I have pushed myself to learn as much as I can
                    about the amazing world of software development. Along the way
                    I have been doing freelance work and have had the opportunity
                    to do an internship at 3 different companies.
                </p>
                </div>
                <div className="col-xxl-5 col-xl-6 col-lg-7 d-none d-lg-block">
                <img alt="Dylan Strohschein" className="img-fluid Intro-Image" src={me}></img>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Intro;